import Typography from "typography"

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  headerColor: "rgb(228,33,54)",
  headerFontFamily: [
    "NexaBlack",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  bodyColor: "rgb(29,29,29)",
  bodyFontFamily: ["NexaBook", "serif"],
  overrideStyles: ({ rhythm }) => ({
    a: {
      color: "rgb(228,33,54)",
      textDecoration: "underline",
    },
    "a:hover": {
      textDecoration: "none",
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
